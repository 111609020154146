import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { dateFormat } from '../helpers/formats'
import Layout from '../components/layout'
import PromptButton from '../components/promptButton'
import PublicationCard from '../components/publicationCard'
import styles from '../styles/stock.module.css'
import BackArrow from '../assets/back-arrow.svg'
import TickIcon from '../assets/tick.svg'
import CloseIcon from '../assets/close.svg'
import LaunchIcon from '../assets/launch.svg'

const Stock = ({data}) => {

    const { airtableStock:stock } = data
	const { Name:name, Benchmarked:benchmarked, Availability_date:availability, Inactivation_method:inactivationMethod, GISAID:gisaid, Concentration:concentration, Source:source, Common_name:commonName, Viral_range:viralRange, Related_news:publications, MSDS:msds, Batch_number:batch } = stock.data

    const HandleMaterialRequest = () => {
        window.open('https://airtable.com/shrHLcNU8vYg6uILZ', '_blank')
    }

    return (
        <Layout>
            <Link to={`../`}><div className={styles.back}><BackArrow/><span>Back to stock</span></div></Link>
            <div className={styles.stock}>
                <div className={styles.header}>
                    <h2>{name}</h2>
                    <PromptButton primary={true} handleClick={HandleMaterialRequest}>Request materials</PromptButton>
                </div>
                <div className={styles.info}>
                    <div>{'Benchmark:'}</div><div>{benchmarked? <TickIcon/> : <CloseIcon/>}</div>
                    {
                        commonName && (<><div>{'Variant:'}</div><div>{commonName}cl</div></>)
                    }
                    {
                        availability && (<><div>{'Availavility:'}</div><div>{availability}</div></>)
                    }
                    {
                        source && (<><div>{'Source:'}</div><div>{source}</div></>)
                    }
                    {
                        inactivationMethod && (<><div>{'Inactivation method:'}</div><div>{inactivationMethod}</div></>)
                    }
                    {
                        gisaid && (<><div>{'GISAID:'}</div><div>{gisaid}</div></>)
                    }
                    {
                        concentration && (<><div>{'Concentration:'}</div><div>{concentration}</div></>)
                    }
                    {
                        viralRange && (<><div>{'Viral range:'}</div><div>{viralRange}</div></>)
                    }
                    {
                        batch && (<><div>{'Batch #:'}</div><div>{batch}</div></>)
                    }
                    {
                        msds && (
                            <>
                                <div>{'MSDS:'}</div>
                                <div className={styles.msdsList}>
                                    <a key={msds[0].id} href={msds[0].data.File?.[0]?.url} target={'_blank'} rel={'noreferrer'} className={styles.msds}><LaunchIcon/>{msds[0].data.Name} ({dateFormat(new Date(msds[0].data.Date))})</a>
                                </div>
                                {
                                    msds.length > 1 && (
                                        <>
                                            <div>{'Older MSDS:'}</div>
                                            <div className={styles.msdsList}>
                                                {
                                                    msds.slice(1).map(msds => (
                                                        <a key={msds.id} href={msds.data.File?.[0]?.url} target={'_blank'} rel={'noreferrer'} className={styles.msds}><LaunchIcon/>{msds.data.Name} ({dateFormat(new Date(msds.data.Date))})</a>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                {
                    publications && (
                        <>
                            <h3>Related publications</h3>
                            <div className={styles.publications}>
                                {
                                    publications.map((publication, i) => (
                                        <PublicationCard key={i} title={publication.data.Title} source={publication.data.Source} date={publication.data.Date} abstract={publication.data.Abstract}/>
                                    ))
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String!) {
        airtableStock(data: {Slug: {eq: $slug}}) {
            data {
                Name
                Slug
                Benchmarked
                Availability_date
                Inactivation_method
                Isolate
                GISAID
                Concentration
                Source
                Viral_range
                Related_news {
                    data {
                        Title
                        Source
                        Date
                        Abstract
                    }
                }
                Common_Name
                MSDS {
                    id
                    data {
                        Date
                        Name
                        File {
                            url
                      }
                    }
                }
                Batch_number
            }
        }
    }
`

export default Stock
